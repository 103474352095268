<template>
	<div class="h-full flex flex-col" v-if="restaurant">
		<TopBar :showBack="true"/>
		<div class="pt-4 text-gray-700 overflow-y-scroll flex-grow">
			<div class="relative">
				<div class="absolute w-full h-64 top-0 flex items-center px-3">
					<div>
						<button @click="currentPhotoIndex>0 ? currentPhotoIndex-- : currentPhotoIndex=restaurant.photos.length-1">
							<font-awesome-icon icon="fa-solid fa-chevron-left" class="text-gray-900 px-3 py-2 bg-gray-200 opacity-70 rounded-full"/>
						</button>
					</div>
					<div class="flex-1"></div>
					<div>
						<button @click="currentPhotoIndex>=restaurant.photos.length-1 ? currentPhotoIndex=0 : currentPhotoIndex++">
							<font-awesome-icon icon="fa-solid fa-chevron-right" class="text-gray-900 px-3 py-2 bg-gray-200 opacity-70 rounded-full"/>
						</button>
					</div>
				</div>
				<img 
					v-for="(photo, index) in restaurant.photos"
					:key="photo.id"
					class="rounded-xl h-64 object-cover w-full"
					:src="photo.path"
					v-show="index === currentPhotoIndex"
				>
			</div>
			<div class="flex justify-center gap-2 mt-4">
				<div 
					:class="index === currentPhotoIndex ? 'bg-red-500' : 'bg-gray-300'"
					class="h-2 w-2 rounded-full"
					v-for="(photo, index) in restaurant.photos"
					:key="photo.id"
					@click="currentPhotoIndex = index"
				></div>
			</div>
			<div class="mt-4 text-2xl font-gothic text-black">
				<span>{{ restaurant.name }}</span>
				<button class="ml-2">
					<font-awesome-icon 
						:icon="restaurant.favorite ? 'fa-solid fa-star' : 'far fa-star'" 
						class="text-red-500 text-xl"
						@click="toggleFavorite(restaurant)"
					/>
				</button>
			</div>
			<div class="pt-0 text-md" v-show="restaurant.cuisines.length">
				{{ restaurant.cuisines.map( x => x.label ).join(", ") }}
			</div>
			<div class="flex mt-6">
				<div class="flex-1 pr-4">
					{{ restaurant.formatted_address }}
				</div>
				<div class="text-right text-red-500 font-bold">
					{{ restaurant.pricing }}
				</div>
			</div>
		</div>
		<div id="bottom_buttons" class="w-full pt-10 pb-6 bg-gray-50 space-y-4">
			<button v-show="$route.params.restaurant_uuid" class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="selectRestaurant">
				<font-awesome-icon icon="fa-solid fa-comments" class="text-white mr-2"/> Notify Chat
			</button>
			<a :href="`tel:${restaurant.phone}`" class="block text-center bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg">
				<font-awesome-icon icon="fa-solid fa-phone" class="text-white mr-2"/> Call restaurant
			</a>
			<a target="_blank" rel="noreferer noopener" :href="`https://maps.google.com/?q=${restaurant.formatted_address}`" class="block text-center bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg">
				<font-awesome-icon icon="fa-solid fa-map-location-dot" class="text-white mr-2"/> Get directions
			</a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	export default {
		components: {
			TopBar
		},
		data() {
			return {
				loading_images: false,
				currentPhotoIndex: 0,
				restaurant: null
			}
		},
		computed: {
			photosStyle(){
				return {
					left: (-9 * this.photo_scroll) + 'rem'
				}
			},
		},
		watch: {
			restaurant(newVal, oldVal){
				if (oldVal === null && newVal){
					this.$store.dispatch('restaurants/fetchRestaurantPhotos', this.restaurant.uuid).then( r => {
						this.loading_images = false;
						this.restaurant.photos = r.data;
						this.fetchRestaurant();
					});
				}
			}
		},
		mounted() {
			this.loading_images = true;
			this.fetchRestaurant();
		},
		methods: {
			selectRestaurant(){
				this.$store.dispatch('table/postChat', {
					message: `I want to go to <span class='text-red font-bold'>${this.restaurant.name}</span>`,
					uuid: this.$route.params.uuid
				}).then( () => {
					this.$router.push( { name: 'chat', params: { uuid: this.$route.params.uuid } })
				});
			},
			fetchRestaurant(){
				this.$store.dispatch('restaurants/getRestaurant', {
					uuid: this.$route.params.restaurant_uuid
				}).then( ({data}) => {
					this.restaurant = data;
				});
			},
			toggleFavorite(restaurant){
				this.$store.dispatch('restaurants/toggleFavorite', {
					uuid: restaurant.uuid,
					favorite: !restaurant.favorite
				}).then( () => {
					this.fetchRestaurant();
					this.$store.dispatch('restaurants/getFavoriteRestaurants');
				});
			}
		}
	}
</script>